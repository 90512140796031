export interface SpamPreview {
  id: string;
  sendingDomain?: string;
  mode: Mode;
  status: SpamPreviewTestStatus | number;
  createdOn: string;
  lastModifiedOn: string;
  processId: string;
  fromAddress: string;
  results: SpamResult[];
  accountKey?: string;
}

export enum Mode {
  EMAIL,
  TEMPLATE,
}

export enum SpamPreviewTestStatus {
  UNKNOWN = -1,
  INITIALIZING = 0,
  TEST_INITIALIZED = 1,
  SEED_TEST_SENT = 2,
  PENDING = 3,
  COMPLETED = 4,
  ERROR = 5,
  ABANDONED = 6,
  FETCHING = 7,
}
export enum SpamPreviewAppStatus {
  PENDING,
  COMPLETED,
  ERROR,
  ABANDONED,
}

export enum SpamResultStatus {
  PASS = 'PASSED',
  FAIL = 'FAILED',
  PENDING = 'PENDING',
  ABANDONED = 'ABANDONED',
  NA = 'N/A',
}
export interface SpamResult {
  applicationLongName: string;
  status: SpamPreviewAppStatus | number;
  applicationName: string;
  failureDescription?: string | null;
  spamScore: number;
  isPassed?: boolean;
  spamHeaders?: string[];
}
