import {
  GmailAndroid10_0,
  GmailAndroid10_0Dark,
  OutlookAndroid7_0,
  iPadMini,
  iPadPro10_5inch,
  iPadPro11inch,
  iPadPro12_9inch,
  iPhone11,
  iPhone11Pro,
  iPhone11ProDark,
  iPhone11ProMax,
  iPhone12,
  iPhone12Dark,
  iPhone12ProMax,
  iPhone12ProMaxDark,
} from './DeviceCssConfig';

import { LitmusDeviceIds } from './LitmusDeviceIds';

export interface DeviceDetails {
  container: {
    backgroundDeviceUrl?: string;
    bgXPos?: string | number;
    bgYPos?: string | number;
    containerWidth?: string;
    containerHeight?: string;
    containerLeft?: string;
    containerTop?: string;
    borderRadius?: string;
  };

  screenWidth?: string;
  screenHeight?: string;
  screenPosition?: string;
  screenImgPosition?: string;
  deviceBorderRadius?: string;
}

export function getDeviceDetails(device: LitmusDeviceIds): DeviceDetails | undefined {
  switch (device) {
    case LitmusDeviceIds.IPHONE11:
      return iPhone11;
    case LitmusDeviceIds.IPHONE11PRO:
      return iPhone11Pro;
    case LitmusDeviceIds.IPHONE11PROMAX:
      return iPhone11ProMax;
    case LitmusDeviceIds.IPHONE11DARK:
      return iPhone12Dark;
    case LitmusDeviceIds.IPHONE11PRODARK:
      return iPhone11ProDark;
    case LitmusDeviceIds.IPHONE12MINI:
      return iPhone12;
    case LitmusDeviceIds.IPHONE12MINIDARK:
      return iPhone12Dark;
    case LitmusDeviceIds.IPHONE12:
      return iPhone12;
    case LitmusDeviceIds.IPHONE12DARK:
      return iPhone12Dark;
    case LitmusDeviceIds.IPHONE12PROMAX:
      return iPhone12ProMax;
    case LitmusDeviceIds.IPHONE12PROMAXDARK:
      return iPhone12ProMaxDark;
    case LitmusDeviceIds.IPHONE13:
      return iPhone12;
    case LitmusDeviceIds.IPHONE13DARK:
      return iPhone12Dark;
    case LitmusDeviceIds.IPHONE13PROMAX:
      return iPhone12ProMax;
    case LitmusDeviceIds.IPHONE13PROMAXDARK:
      return iPhone12ProMaxDark;
    case LitmusDeviceIds.IPHONE14:
      return iPhone12;
    case LitmusDeviceIds.IPHONE14DARK:
      return iPhone12Dark;
    case LitmusDeviceIds.IPHONE14PROMAX:
      return iPhone12ProMax;
    case LitmusDeviceIds.IPHONE14PROMAXDARK:
      return iPhone12ProMaxDark;
    case LitmusDeviceIds.IPHONE15:
      return iPhone12;
    case LitmusDeviceIds.IPHONE15DARK:
      return iPhone12Dark;
    case LitmusDeviceIds.IPHONE15PLUS:
      return iPhone12ProMax;
    case LitmusDeviceIds.IPHONE15PLUSDARK:
      return iPhone12ProMaxDark;
    case LitmusDeviceIds.IPADPRO11IN:
      return iPadPro11inch;
    case LitmusDeviceIds.IPADPRO13IN:
      return iPadPro12_9inch;
    case LitmusDeviceIds.IPAD:
    case LitmusDeviceIds.IPADPRO10P5IN:
      return iPadPro10_5inch;
    case LitmusDeviceIds.IPADMINI6DARK:
    case LitmusDeviceIds.IPADMINI6:
    case LitmusDeviceIds.IPADAIRGEN4:
    case LitmusDeviceIds.IPADGEN7:
    case LitmusDeviceIds.IPADGEN7DARK:
      return iPadMini;
    case LitmusDeviceIds.ANDROID11GMAILAPP:
      return GmailAndroid10_0;
    case LitmusDeviceIds.ANDROIDOUTLOOK:
      return GmailAndroid10_0;
    case LitmusDeviceIds.ANDROID11GMAILAPPDARK:
      return GmailAndroid10_0Dark;
    case LitmusDeviceIds.ANDROIDOUTLOOKDARK:
      return OutlookAndroid7_0;
    default:
      return iPhone12;
  }
}
