export const GmailAndroid10_0Dark = {
  container: {
    backgroundDeviceUrl: 'images/google-pixel-4.png',
    containerWidth: '360px',
    containerHeight: '760px',
    containerLeft: '15px',
    containerTop: '49px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '32px',
  },
  screenWidth: '393px',
  screenHeight: '833px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
export const GmailAndroid10_0 = {
  container: {
    backgroundDeviceUrl: 'images/google-pixel-4.png',
    containerWidth: '360px',
    containerHeight: '760px',
    containerLeft: '15px',
    containerTop: '49px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '36px',
  },
  screenWidth: '393px',
  screenHeight: '833px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
export const iPadMini = {
  container: {
    backgroundDeviceUrl: 'images/ipad-mini.png',
    containerWidth: '768px',
    containerHeight: '958px',
    containerLeft: '61px',
    containerTop: '208px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '0px',
  },
  screenWidth: '892px',
  screenHeight: '1309px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
export const iPadPro10_5inch = {
  container: {
    backgroundDeviceUrl: 'images/ipad-pro-10.5inch.png',
    containerWidth: '834px',
    containerHeight: '1048px',
    containerLeft: '36px',
    containerTop: '161px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '0px',
  },
  screenWidth: '902px',
  screenHeight: '1305px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
export const iPadPro11inch = {
  container: {
    backgroundDeviceUrl: 'images/ipad-pro-11inch.png',
    containerWidth: '834px',
    containerHeight: '1194px',
    containerLeft: '54px',
    containerTop: '55px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '10px',
  },
  screenWidth: '946px',
  screenHeight: '1300px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
export const iPadPro12_9inch = {
  container: {
    backgroundDeviceUrl: 'images/ipad-pro-12.9inch.png',
    containerWidth: '1024px',
    containerHeight: '1300px',
    containerLeft: '62px',
    containerTop: '177px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '0px',
  },
  screenWidth: '1148px',
  screenHeight: '1590px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
export const iPadPro = {
  container: {
    backgroundDeviceUrl: 'images/ipad-pro.png',
    containerWidth: '1024px',
    containerHeight: '1300px',
    containerLeft: '62px',
    containerTop: '178px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '0px',
  },
  screenWidth: '1148px',
  screenHeight: '1590px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
export const iPhone11ProDark = {
  container: {
    backgroundDeviceUrl: 'images/iphone-11-pro-dark.png',
    containerWidth: '375px',
    containerHeight: '639px',
    containerLeft: '26px',
    containerTop: '111px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '0px',
  },
  screenWidth: '428px',
  screenHeight: '858px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '63px',
};
export const iPhone11ProMax = {
  container: {
    backgroundDeviceUrl: 'images/iphone-11-pro-max.png',
    containerWidth: '414px',
    containerHeight: '722px',
    containerLeft: '24px',
    containerTop: '108px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '0px',
  },
  screenWidth: '478px',
  screenHeight: '968px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
export const iPhone11Pro = {
  container: {
    backgroundDeviceUrl: 'images/iphone-11-pro.png',
    containerWidth: '375px',
    containerHeight: '639px',
    containerLeft: '26px',
    containerTop: '111px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '0px',
  },
  screenWidth: '428px',
  screenHeight: '858px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '63px',
};
export const iPhone11 = {
  container: {
    backgroundDeviceUrl: 'images/iphone-11.png',
    containerWidth: '414px',
    containerHeight: '724px',
    containerLeft: '38px',
    containerTop: '124px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '0px',
  },
  screenWidth: '490px',
  screenHeight: '968px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
export const iPhone12Dark = {
  container: {
    backgroundDeviceUrl: 'images/iphone-12-dark.png',
    containerWidth: '390px',
    containerHeight: '673px',
    containerLeft: '24px',
    containerTop: '108px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '0px',
  },
  screenWidth: '438px',
  screenHeight: '886px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
export const iPhone12ProMaxDark = {
  container: {
    backgroundDeviceUrl: 'images/iphone-12-pro-max-dark.png',
    containerWidth: '428px',
    containerHeight: '752px',
    containerLeft: '25px',
    containerTop: '112px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '0px',
  },
  screenWidth: '478px',
  screenHeight: '968px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
export const iPhone12ProMax = {
  container: {
    backgroundDeviceUrl: 'images/iphone-12-pro-max.png',
    containerWidth: '428px',
    containerHeight: '751px',
    containerLeft: '25px',
    containerTop: '112px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '0px',
  },
  screenWidth: '478px',
  screenHeight: '968px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
export const iPhone12 = {
  container: {
    backgroundDeviceUrl: 'images/iphone-12.png',
    containerWidth: '390px',
    containerHeight: '673px',
    containerLeft: '24px',
    containerTop: '108px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '0px',
  },
  screenWidth: '438px',
  screenHeight: '886px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
export const OutlookAndroid7_0 = {
  container: {
    backgroundDeviceUrl: 'images/google-pixel.png',
    containerWidth: '360px',
    containerHeight: '640px',
    containerLeft: '21px',
    containerTop: '84px',
    bgXPos: 0,
    bgYPos: 0,
    borderRadius: '0px',
  },
  screenWidth: '405px',
  screenHeight: '820px',
  screenPosition: 'relative',
  screenImgPosition: 'absolute',
  deviceBorderRadius: '0px',
};
