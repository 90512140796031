import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AppContainer from './containers/appContainer/AppContainer';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import { QueryParamsProvider } from './contexts/QueryParamsContext';

function App() {
  return (
    <BrowserRouter>
      <QueryParamsProvider>
        <Routes>
          <Route
            path="/emailPreviews"
            element={<AppContainer />}
          ></Route>
          <Route
            path="*"
            element={<ErrorPage error={"This url path doesn't exist!"} />}
          />
        </Routes>
      </QueryParamsProvider>
    </BrowserRouter>
  );
}

export default App;
