import { type ReactNode } from 'react';

type Tab = {
  tabId: number;
  tabName: string;
};

interface TabbedContentProps {
  tabs: Tab[];
  children: ReactNode[];
  activeTab: number;
  onTabChange: (tabId: number) => void;
}

export const TabbedContent = ({ tabs, children, activeTab, onTabChange }: TabbedContentProps) => {
  const handleTabClick = (tabId: number) => {
    onTabChange(tabId);
  };

  return (
    <div className="p-4">
      <div className="flex pl-4">
        {tabs.map((tab) => (
          <button
            key={tab.tabId}
            onClick={() => handleTabClick(tab.tabId)}
            className={`mr-2 py-2 px-4 rounded-t-lg bg-blue-500 focus:outline-none ${
              activeTab === tab.tabId ? 'theme-blue-background text-white' : 'bg-gray-200'
            }`}
          >
            {tab.tabName}
          </button>
        ))}
      </div>
      <div className=" p-4 rounded-b-lg">
        {children.map((child, index) => (
          <div
            key={index}
            style={{ display: activeTab === (tabs[index]?.tabId ?? 0) ? 'block' : 'none' }}
          >
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};
