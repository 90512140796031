import React, { useEffect, useState } from 'react';

import { ClickLoadingIndicator } from '..';
import { Tooltip } from 'react-tooltip';

export type ThumbnailCardProps = {
  thumbnailUrl: string;
  text: string;
  thumbnailSize?: { width: number; height: number };
  onClick: () => void;
  showLoader?: boolean;
};

const ThumbnailCard: React.FC<ThumbnailCardProps> = ({
  thumbnailUrl,
  text,
  thumbnailSize = { width: 128, height: 100 },
  onClick,
  showLoader = true,
}) => {
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const [error, setError] = useState<string | null>(null); // Track any errors during loading

  const handleError = (error: unknown) => {
    setError('Error fetching thumbnail' + error); // Set a user-friendly error message
    setIsLoading(false); // Clear loading state even on error
  };

  const handleClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    onClick();
  };

  useEffect(() => {
    const fetchThumbnail = async () => {
      setIsLoading(true); // Set loading state before fetching
      setError(null); // Clear previous error messages

      try {
        const response = await fetch(thumbnailUrl); // Fetch the thumbnail
        if (!response.ok) {
          throw new Error(`Failed to fetch thumbnail: ${response.status}`);
        }

        setIsLoading(false); // Clear loading state on successful fetch
        // Update the image source using the thumbnailUrl directly (see below)
      } catch (error) {
        handleError(error);
      }
    };

    if (thumbnailUrl) {
      fetchThumbnail();
    }
  }, [thumbnailUrl]); // Re-fetch on thumbnailUrl change

  // Conditional Image Rendering with Loading State and Error Handling:
  let imageContent;
  if (isLoading) {
    imageContent = (
      <ClickLoadingIndicator
        isLoading={showLoader && isLoading}
        type={'thumb'}
      />
    ); // Provide a loading indicator
  } else if (error) {
    imageContent = <div className="thumbnail-error">{error}</div>; // Display an error message
  } else {
    imageContent = (
      <img
        src={thumbnailUrl} // Access the updated image URL (see below)
        alt="Thumbnail"
        style={{ height: thumbnailSize.height, width: '100%', objectFit: 'cover' }}
      />
    );
  }

  return (
    <>
      <div
        data-tooltip-id={text}
        data-tooltip-content={text}
        className="w-32 rounded overflow-hidden shadow-lg hover:scale-105 transition-transform duration-300"
        onClick={handleClick}
      >
        <a
          href="#"
          style={{ display: 'inline-block', width: thumbnailSize.width }}
        >
          {imageContent}
        </a>
        <div
          className="px-2 py-2"
          style={{ height: '2rem', width: '100%' }}
        >
          <div
            className="text-md mb-2"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {text}
          </div>
        </div>
      </div>
      <Tooltip
        id={text}
        place="top"
      />
    </>
  );
};

export default ThumbnailCard;

// Optional CSS for loading indicator and error styling:
