import React from 'react';

interface ErrorPageProps {
  error: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
  error = error ?? 'An Error has occurred. Please try again later.';
  return (
    <div className="flex items-center justify-center h-full bg-gray-100 min-h-96">
      <div className="bg-white p-8 rounded shadow">
        <h1 className="text-2xl font-bold mb-4">Error</h1>
        <p className="text-red-500">{error}</p>
      </div>
    </div>
  );
};

export default ErrorPage;
