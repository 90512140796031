import type { IEnvironment } from '../types/environment.interface';

declare global {
  interface Window {
    isProduction: boolean;
    EMAIL_PREVIEWS_API_URL: string;
    workerStateUrl: string;
  }
}

export const environment: IEnvironment = {
  production: window.isProduction,
  EMAIL_PREVIEWS_API_URL: window.EMAIL_PREVIEWS_API_URL,
  workerStateUrl: window.workerStateUrl,
};
