import { useEffect, useState } from 'react';

const useCombinedLoading = (...loadingStates: boolean[]) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const anyLoading = loadingStates.some((state) => state);
    setIsLoading(anyLoading);
  }, [loadingStates]);

  return isLoading;
};

export default useCombinedLoading;
