import './ClickLoadingIndicator.css'; // Optional CSS file for styling

import React from 'react';
import loadingGifPath from '../../assets/images/loading.gif';

interface ClickLoadingIndicatorProps {
  isLoading: boolean;
  type: 'page' | 'thumb';
}

export const ClickLoadingIndicator: React.FC<ClickLoadingIndicatorProps> = ({
  isLoading,
  type = 'page',
}) => {
  return isLoading ? (
    <div
      role="loading-spinner"
      className={
        type === 'page'
          ? 'fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none'
          : 'image z-50 flex items-center justify-center'
      }
    >
      <div
        className={
          type === 'page' ? 'fixed inset-0 transition-opacity bg-gray-200 opacity-50 page' : 'thumb'
        }
      ></div>
      <div className={`loading-indicator ${isLoading ? 'show' : ''}`}>
        <img
          className={type}
          src={loadingGifPath}
          alt="Loading..."
        />
      </div>
    </div>
  ) : null;
};
