import './TopBar.css';

import React from 'react';
import logo from '../../assets/images/cd_logo.png';

interface TopBarProps {
  orgName: string;
  remainingCredits: number | '...';
}

export const TopBar: React.FC<TopBarProps> = ({ orgName, remainingCredits }: TopBarProps) => {
  const memoizedTopBar = React.useMemo(() => {
    return (
      <div
        data-testid="top-bar"
        className="top-bar theme-blue-background text-white flex justify-between items-center px-4 py-2"
      >
        <div className="flex items-center">
          <img
            src={logo}
            alt="Logo"
            className="h-6 w-6 mr-2"
          />
          <h2 className="text-white text-xl font-normal px-2">Inbox Preview</h2>
          <div className="border-l-2 border-white px-2">
            <h2> {orgName} </h2>
          </div>
        </div>
        <p>{remainingCredits} previews remaining (this month)</p>
      </div>
    );
  }, [orgName, remainingCredits]);

  return memoizedTopBar;
};
