import { useEffect, useState } from 'react';

import { environment } from '@environment';

const endpoint = environment.EMAIL_PREVIEWS_API_URL;

const useGetSendingDomains = (
  accountKey: string,
): { sendingDomains: string[]; loading: boolean } => {
  const [sendingDomains, setSendingDomains] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  if (accountKey === '') {
    console.error('Account key is required to fetch sending domains');
  }
  useEffect(() => {
    if (accountKey) {
      const sendingDomainEndpoint = `${endpoint}/sendingdomain?accountKey=${accountKey}`;
      setLoading(true);
      fetch(sendingDomainEndpoint, { credentials: 'include' })
        .then((response) => response.json())
        .then((data) => {
          setSendingDomains(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [accountKey]);

  return { sendingDomains, loading };
};

export default useGetSendingDomains;
