import './SpamTestResult.css';

import React, { useMemo } from 'react';
import { SpamPreviewTestStatus, SpamResultStatus, type SpamResult } from '../../types/spam-preview';
import { ClickLoadingIndicator } from '..';

type ErrorStates = SpamPreviewTestStatus.ABANDONED | SpamPreviewTestStatus.ERROR | false;
type SpamProviderProps = {
  result: SpamResult;
  errorState: ErrorStates;
};

export const SpamTestResult: React.FC<SpamProviderProps> = ({ result, errorState = false }) => {
  const resultText: SpamResultStatus = useMemo(
    () => getResultText(result, errorState),
    [result, errorState],
  );
  const statusClass = useMemo(() => getStatusClass(resultText), [resultText]);
  const memoizedSpan = useMemo(
    () => (
      <span
        className="theme-blue-text-color gray-bottom-border app-name"
        style={{ width: 'fit-content' }}
      >
        {result.applicationLongName}
      </span>
    ),
    [result.applicationLongName],
  );

  return useMemo(
    () => (
      <li
        key={result.applicationName}
        style={{ display: 'flex', flexDirection: 'column' }}
        className={`mail-provider-list-item ${statusClass} pb-6`}
      >
        {memoizedSpan}
        <div className="results gray-bottom-border flex">
          <span>{resultText}</span>
          {!errorState && (
            <div className="w-8 pl-2">
              <ClickLoadingIndicator
                isLoading={resultText === SpamResultStatus.PENDING}
                type="thumb"
              />
            </div>
          )}
        </div>
        {!result.isPassed && <span className="error-description">{result.failureDescription}</span>}
      </li>
    ),
    [
      result.applicationName,
      result.isPassed,
      result.failureDescription,
      statusClass,
      memoizedSpan,
      resultText,
      errorState,
    ],
  );
};

const getResultText = (result: SpamResult, errorState: ErrorStates): SpamResultStatus => {
  const abandoned = errorState === SpamPreviewTestStatus.ABANDONED;
  const error = errorState === SpamPreviewTestStatus.ERROR;
  if (error) {
    return SpamResultStatus.NA;
  } else if (result.isPassed === false && result.status === 0) {
    return abandoned ? SpamResultStatus.ABANDONED : SpamResultStatus.PENDING;
  } else {
    return result.isPassed ? SpamResultStatus.PASS : SpamResultStatus.FAIL;
  }
};

const getStatusClass = (resultText: SpamResultStatus): string => {
  switch (resultText) {
    case SpamResultStatus.PASS:
      return 'green-text';
    case SpamResultStatus.FAIL:
      return 'red-text';
    case SpamResultStatus.PENDING:
      return 'yellow-text';
    default:
      return '';
  }
};
