import { useEffect, useState } from 'react';

import { environment } from '@environment';

const endpoint = environment.EMAIL_PREVIEWS_API_URL;

const useRemainingCredits = (accountKey: string) => {
  const [remainingCredits, setRemainingCredits] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  if (accountKey === '') {
    console.error('Account key is required to fetch remaining credits');
  }

  useEffect(() => {
    const fetchRemainingCredits = async () => {
      try {
        setIsLoading(true);
        const creditEndpoint = `${endpoint}/credit?accountKey=${accountKey}`;
        const response = await fetch(creditEndpoint, { credentials: 'include' });
        const data = await response.json();
        setRemainingCredits(data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchRemainingCredits();
  }, [accountKey]);

  return { remainingCredits, setRemainingCredits, isLoading };
};

export default useRemainingCredits;
