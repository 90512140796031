import { v4 as uuidv4 } from 'uuid';

// Generate UUID for connectionId String
export const uniqueID = uuidv4();

// Define a type or interface for AuthParams
interface AuthParams {
  accountKey: string;
  sessionId: string;
}

// Export the buildHeaders function
export function buildHeaders(params: AuthParams) {
  const { accountKey, sessionId } = params;
  return { accountKey, sessionId };
}
