import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useEffect, useRef, useState } from 'react';

import type { AuthParams } from '../types/worker-state';
import axios from 'axios';
import { buildHeaders } from '../services/header-helper';
import { environment } from '@environment';
import { v4 as uuidv4 } from 'uuid';

const workerStateEndpoint = environment.workerStateUrl;
const subscribeUrl = `${workerStateEndpoint}/api/subscription/email-previews/{emailSendId}/{connectionId}`;
const cdWorkerState = 'ClickDimensionsWorkerState';
const getFormattedUrl = (emailSendId: string, userConnectionId: string): string => {
  return subscribeUrl
    .replace('{emailSendId}', emailSendId)
    .replace('{connectionId}', userConnectionId);
};

type UseSignalRResult = {
  connected: boolean;
  error: string | null;
  on: (handler: (d: unknown) => void) => void;
  off: (handler: (...args: unknown[]) => void) => void;
  subscribeToNotifications: () => Promise<void>;
};

const useSpamTestNotification = (emailSendId: string, options: AuthParams): UseSignalRResult => {
  const { accountKey, sessionId } = options;
  const [userConnectionId] = useState<string>(uuidv4());
  const connectionRef = useRef<HubConnection | null>(null); // Create a useRef to store the connection
  const [connected, setConnected] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  useEffect(() => {
    const headers = { accountKey, sessionId };
    const url = workerStateEndpoint + '/api/subscription/' + userConnectionId + '/';
    const createConnection = async (): Promise<void> => {
      try {
        if (!connected) {
          connectionRef.current = new HubConnectionBuilder()
            .withUrl(url, { headers, withCredentials: true })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

          connectionRef.current.onclose((error) => {
            setConnected(false);
            console.error('SignalR connection closed:', error);
            setError(
              'Spam Test Notification connection closed. Please refresh the page and try again.',
            );
          });
          await connectionRef.current.start();
          setConnected(true);
          console.log('SignalR connection established.');
          setError(null);
        }
      } catch (err: unknown) {
        setConnected(false);
        console.error('Error establishing SignalR connection:', err);
        setError(
          'Spam Test Notification connection closed. Please refresh the page and try again.',
        );
      }
    };

    createConnection();

    return () => {
      if (connectionRef.current) {
        connectionRef.current.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const on = (handler: (d: unknown) => void): void => {
    if (connectionRef.current) {
      connectionRef.current.on(cdWorkerState, handler);
    } else {
      console.warn('SignalR connection not established. Event handler not attached.');
    }
  };

  const off = (handler: (d: unknown) => void): void => {
    if (connectionRef.current) {
      connectionRef.current.off(cdWorkerState, handler);
    }
  };

  const subscribeToNotifications = async () => {
    try {
      const url = getFormattedUrl(emailSendId, userConnectionId);
      await axios
        .post<unknown>(url, null, { headers: buildHeaders(options), withCredentials: true })
        .then((message) => {
          console.log('Successfully connected to worker state', message);
        })
        .catch((error) => {
          console.error('Error subscribing to notifications:', error); // Log the error details
        });
      console.log('Subscribed to notifications.');
    } catch (error) {
      console.error('Error subscribing to notifications:', error);
    }
  };

  return { connected, error, on, off, subscribeToNotifications };
};

export default useSpamTestNotification;
