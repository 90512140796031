export enum LitmusDeviceIds {
  IPAD = 'IPAD',
  IPADPRO13IN = 'IPADPRO13IN',
  IPHONESE = 'IPHONESE',
  OUTLOOKIOS = 'OUTLOOKIOS',
  GMAILIOS = 'GMAILIOS',
  ANDROIDOUTLOOK = 'ANDROIDOUTLOOK',
  IPADPRO10P5IN = 'IPADPRO10P5IN',
  IPHONE11 = 'IPHONE11',
  IPHONE11PRO = 'IPHONE11PRO',
  IPHONE11PROMAX = 'IPHONE11PROMAX',
  IPHONE11DARK = 'IPHONE11DARK',
  IPHONE11PRODARK = 'IPHONE11PRODARK',
  IPADGEN7 = 'IPADGEN7',
  IPADGEN7DARK = 'IPADGEN7DARK',
  IPADPRO11IN = 'IPADPRO11IN',
  IPHONESE2020 = 'IPHONESE2020',
  GMAILIOSDARK = 'GMAILIOSDARK',
  IPHONE12DARK = 'IPHONE12DARK',
  IPHONE12 = 'IPHONE12',
  IPHONE12MINI = 'IPHONE12MINI',
  IPHONE12MINIDARK = 'IPHONE12MINIDARK',
  IPHONE12PROMAX = 'IPHONE12PROMAX',
  IPHONE12PROMAXDARK = 'IPHONE12PROMAXDARK',
  IPADAIRGEN4 = 'IPADAIRGEN4',
  IPHONE13 = 'IPHONE13',
  IPHONE13DARK = 'IPHONE13DARK',
  IPHONE13PROMAX = 'IPHONE13PROMAX',
  IPHONE13PROMAXDARK = 'IPHONE13PROMAXDARK',
  IPADMINI6 = 'IPADMINI6',
  IPADMINI6DARK = 'IPADMINI6DARK',
  ANDROID11GMAILAPP = 'ANDROID11GMAILAPP',
  ANDROID11GMAILAPPDARK = 'ANDROID11GMAILAPPDARK',
  ANDROIDOUTLOOKDARK = 'ANDROIDOUTLOOKDARK',
  IPHONE14 = 'IPHONE14',
  IPHONE14DARK = 'IPHONE14DARK',
  IPHONE14PROMAX = 'IPHONE14PROMAX',
  IPHONE14PROMAXDARK = 'IPHONE14PROMAXDARK',
  IPHONE15 = 'IPHONE15',
  IPHONE15DARK = 'IPHONE15DARK',
  IPHONE15PLUS = 'IPHONE15PLUS',
  IPHONE15PLUSDARK = 'IPHONE15PLUSDARK',
}
