import { SpamPreviewTestStatus, type SpamPreview } from '../types/spam-preview';
import { useEffect, useState } from 'react';
import { environment } from '@environment';

const endpoint = environment.EMAIL_PREVIEWS_API_URL;
const useSpamFilter = (
  accountKey: string,
  mode: string,
  id: string,
  workerStateStatus: SpamPreviewTestStatus,
) => {
  const [currentSendingDomain, setCurrentSendingDomain] = useState('');
  const [spamTestStatus, setSpamTestStatus] = useState(SpamPreviewTestStatus.UNKNOWN);
  const [spamPreview, setSpamPreview] = useState<SpamPreview | null>(null);
  const [spamLoading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>('');
  const spamPreviewEndpoint = `${endpoint}/spam-preview/${mode}/${id}?accountKey=${accountKey}`;

  switch (workerStateStatus) {
    case SpamPreviewTestStatus.INITIALIZING:
      console.log('Spam Preview is initializing...');
      break;
    case SpamPreviewTestStatus.TEST_INITIALIZED:
      console.log('Spam Preview test is initialized.');
      break;
    case SpamPreviewTestStatus.SEED_TEST_SENT:
      console.log('Seed test sent.');
      break;
    case SpamPreviewTestStatus.PENDING:
      console.log('Spam Preview is pending.');
      break;
    case SpamPreviewTestStatus.COMPLETED:
      console.log('Spam Preview is completed.');
      break;
    case SpamPreviewTestStatus.ERROR:
      console.log('Spam Preview has an error.');
      break;
    case SpamPreviewTestStatus.ABANDONED:
      console.log('Spam Preview was abandoned.');
      break;
    case SpamPreviewTestStatus.FETCHING:
      console.log('Fetching Spam Preview.');
      break;
    default:
      break;
  }
  if (!accountKey) {
    console.error('Account key is required to fetch device previews');
  }
  if (!mode) {
    console.error('Mode is required to fetch spam previews');
  }
  if (!id) {
    console.error('Id is required to fetch spam previews');
  }
  useEffect(() => {
    setSpamPreview(null);
    const fetchSpamPreview = async () => {
      setLoading(true);
      try {
        const response = await fetch(spamPreviewEndpoint, { credentials: 'include' });
        if (response.ok) {
          const data = await response.json();
          setSpamPreview(data);
          setCurrentSendingDomain(data.sendingDomain);
          setSpamTestStatus(data.status);
          setError('');
        } else if (response.status === 404) {
          setError('Spam Preview not found');
        } else {
          setError('Failed to fetch spam preview');
        }
        setLoading(false);
      } catch (ex) {
        setError(JSON.stringify(ex));
        setLoading(false);
      }
    };

    fetchSpamPreview();
  }, [spamPreviewEndpoint, error, workerStateStatus]);

  return { spamPreview, spamLoading, error, currentSendingDomain, spamTestStatus, setSpamPreview };
};

export default useSpamFilter;
