import { SpamPreviewTestStatus, type SpamPreview } from '../../types/spam-preview';

import { ClickLoadingIndicator, SpamTestResult } from '../../components';
import React, { useMemo } from 'react';

const SpamTestResultMemo = React.memo(SpamTestResult);
export interface SpamFilterProps {
  spamPreview: SpamPreview | null;
  testState: SpamPreviewTestStatus;
  loading: boolean;
}

export const SpamFilter: React.FC<SpamFilterProps> = React.memo(
  ({ spamPreview, testState, loading }: SpamFilterProps) => {
    const providers = useMemo(() => spamPreview?.results || [], [spamPreview]);

    const errorState =
      testState === SpamPreviewTestStatus.ABANDONED || testState === SpamPreviewTestStatus.ERROR
        ? testState
        : false;

    const transformToReadableText = (status: SpamPreviewTestStatus): string => {
      if (status === SpamPreviewTestStatus.UNKNOWN) return 'Awaiting Update...';
      if (status === SpamPreviewTestStatus.FETCHING) return 'Fetching...';
      if (status === SpamPreviewTestStatus.ABANDONED) return 'Completed';

      const text = SpamPreviewTestStatus[status];
      // Replace underscores with spaces
      const transformedText = text.replace(/_/g, ' ');

      // Capitalize the first letter of each word
      const words = transformedText.split(' ');
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase(),
      );

      // Join the words back together
      return capitalizedWords.join(' ');
    };

    const textStatus = transformToReadableText(testState);

    const previewStatusTitle = `Spam Test Status: ${textStatus ?? 'Awaiting Update...'}`;

    return (
      <div className={'bg-gray-200 min-h-[74vh]'}>
        <div className="panel-background text-white p-4 flex justify-between items-center h-16">
          <div className="text-lg font-bold">{previewStatusTitle}</div>{' '}
          {testState < SpamPreviewTestStatus.COMPLETED ? (
            <ClickLoadingIndicator
              isLoading={true}
              type="thumb"
            />
          ) : null}
        </div>
        <ul className="mail-provider-list p-2">
          {providers?.map((provider) => (
            <SpamTestResultMemo
              key={provider.applicationName} // Use a unique key for each provider
              result={provider}
              errorState={errorState}
            />
          ))}
          {providers?.length === 0 && (loading || testState < 2) && (
            <div className="flex flex-col space-y-5 mt-1">
              {[...Array(7)].map((_, index) => (
                <div key={index}>
                  <div
                    className="h-7 w-[25vw] bg-gray-300 rounded-sm animate-pulse placeholder-rectangle mb-1"
                    style={{ animationDuration: '1.5s' }}
                  />
                  <div
                    className="h-6 w-[12vw] bg-gray-300 rounded-sm animate-pulse placeholder-rectangle"
                    style={{ animationDuration: '1.5s' }}
                  />
                </div>
              ))}
            </div>
          )}
        </ul>
      </div>
    );
  },
);

SpamFilter.displayName = 'SpamFilter'; // Add display name to the component
